import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore

// prettier-ignore
const ApiKey = () => import('@/entities/api-key/api-key.vue');
// prettier-ignore
const ApiKeyUpdate = () => import('@/entities/api-key/api-key-update.vue');
// prettier-ignore
const ApiKeyDetails = () => import('@/entities/api-key/api-key-details.vue');
// prettier-ignore
const ApplicationUser = () => import('@/entities/application-user/application-user.vue');
// prettier-ignore
const ApplicationUserUpdate = () => import('@/entities/application-user/application-user-update.vue');
// prettier-ignore
const ApplicationUserDetails = () => import('@/entities/application-user/application-user-details.vue');
// prettier-ignore
const ApplicationUserImport = () => import('@/entities/application-user/application-user-import.vue');
// prettier-ignore
// const ElbInvoice = () => import('@/entities/elb-invoice/elb-invoice.vue');
// // prettier-ignore
// const ElbInvoiceUpdate = () => import('@/entities/elb-invoice/elb-invoice-update.vue');
// // prettier-ignore
// const ElbInvoiceDetails = () => import('@/entities/elb-invoice/elb-invoice-details.vue');
// prettier-ignore
const ElbSubscription = () => import('@/entities/elb-subscription/elb-subscription.vue');
// prettier-ignore
const ElbSubscriptionOrg = () => import('@/entities/elb-subscription/elb-subscription-org.vue');
// prettier-ignore
const ElbSubscriptionUpdate = () => import('@/entities/elb-subscription/elb-subscription-update.vue');
// prettier-ignore
const ElbSubscriptionDetails = () => import('@/entities/elb-subscription/elb-subscription-details.vue');
// prettier-ignore
const ElbSubscriptionInternalDetails = () => import('@/entities/elb-subscription/elb-subscription-internal-details.vue');
// prettier-ignore
const Organization = () => import('@/entities/organization/organization.vue');
// prettier-ignore
const OrganizationUpdate = () => import('@/entities/organization/organization-update.vue');
// prettier-ignore
const OrganizationCreate = () => import('@/entities/organization/organization-create.vue');
// prettier-ignore
const OrganizationDetails = () => import('@/entities/organization/organization-details.vue');
// prettier-ignore
const Plan = () => import('@/entities/plan/plan.vue');
// prettier-ignore
const PlanCreate = () => import('@/entities/plan/plan-create.vue');
// prettier-ignore
const PlanUpdate = () => import('@/entities/plan/plan-update.vue');
// prettier-ignore
const PlanDetails = () => import('@/entities/plan/plan-details.vue');
// prettier-ignore
const PlanFeature = () => import('@/entities/plan-feature/plan-feature.vue');
// prettier-ignore
const PlanFeatureUpdate = () => import('@/entities/plan-feature/plan-feature-update.vue');
// prettier-ignore
const PlanFeatureDetails = () => import('@/entities/plan-feature/plan-feature-details.vue');
// prettier-ignore
const Article = () => import('@/entities/article/article.vue');
// prettier-ignore
const ArticleUpdate = () => import('@/entities/article/article-update.vue');
// prettier-ignore
const ArticleDetails = () => import('@/entities/article/article-details.vue');
// prettier-ignore
const Product = () => import('@/entities/product/product.vue');
// prettier-ignore
const ProductUpdate = () => import('@/entities/product/product-update.vue');
// prettier-ignore
const ProductVersionApps = () => import('@/entities/product-version/product-version-apps.vue');
// prettier-ignore
const Home = () => import('@/entities/home/home.vue');
// prettier-ignore
const ProductVersionDetail = () => import('@/entities/product-version/product-version-detail.vue');
// prettier-ignore
const ProductDetails = () => import('@/entities/product/product-details.vue');
// prettier-ignore
const ProductVersion = () => import('@/entities/product-version/product-version.vue');
// prettier-ignore
const ProductVersionUpdate = () => import('@/entities/product-version/product-version-update.vue');
// prettier-ignore
const ProductVersionDetails = () => import('@/entities/product-version/product-version-details.vue');
// prettier-ignore
const Setting = () => import('@/entities/setting/setting.vue');
// prettier-ignore
const SettingUpdate = () => import('@/entities/setting/setting-update.vue');
// prettier-ignore
const SettingInternalUpdate = () => import('@/entities/setting/setting-internal-update.vue');
// prettier-ignore
const SettingCreate = () => import('@/entities/setting/setting-create.vue');
// prettier-ignore
const SettingDetails = () => import('@/entities/setting/setting-details.vue');
// prettier-ignore
const WebHookVersion = () => import('@/entities/web-hook-version/web-hook-version.vue');
// prettier-ignore
const WebHookVersionUpdate = () => import('@/entities/web-hook-version/web-hook-version-update.vue');
// prettier-ignore
const WebHookVersionDetails = () => import('@/entities/web-hook-version/web-hook-version-details.vue');
// prettier-ignore
const WebHookEvent = () => import('@/entities/web-hook-event/web-hook-event.vue');
// prettier-ignore
// const WebHookEventUpdate = () => import('@/entities/web-hook-event/web-hook-event-update.vue');
// prettier-ignore
const WebHookEventDetails = () => import('@/entities/web-hook-event/web-hook-event-details.vue');
// prettier-ignore
const WebHookEndpoint = () => import('@/entities/web-hook-endpoint/web-hook-endpoint.vue');
// prettier-ignore
const WebHookEndpointUpdate = () => import('@/entities/web-hook-endpoint/web-hook-endpoint-update.vue');
// prettier-ignore
const WebHookEndpointDetails = () => import('@/entities/web-hook-endpoint/web-hook-endpoint-details.vue');
// prettier-ignore
const ElbEvent = () => import('@/entities/elb-event/elb-event.vue');
// prettier-ignore
const ElbEventDetails = () => import('@/entities/elb-event/elb-event-details.vue');
// prettier-ignore
const ElbResource = () => import('@/entities/elb-resource/elb-resource.vue');
// prettier-ignore
const ElbResourceUpdate = () => import('@/entities/elb-resource/elb-resource-update.vue');
// prettier-ignore
const ElbResourceDetails = () => import('@/entities/elb-resource/elb-resource-details.vue');
// prettier-ignore
const Region = () => import('@/entities/region/region.vue');
// prettier-ignore
const RegionUpdate = () => import('@/entities/region/region-update.vue');
// prettier-ignore
const HubSpotForm = () => import('@/entities/hub-spot-form/hub-spot-form.vue');
// prettier-ignore
const HubSpotFormUpdate = () => import('@/entities/hub-spot-form/hub-spot-form-update.vue');
// prettier-ignore
const Server = () => import('@/entities/setting/server/server.vue');
// prettier-ignore
const Addon = () => import('@/entities/addon/addon.vue');
// prettier-ignore
const AddonUpdate = () => import('@/entities/addon/addon-update.vue');
// prettier-ignore
const AddonDetails = () => import('@/entities/addon/addon-details.vue');
// prettier-ignore
const Reseller = () => import('@/entities/organization/reseller/reseller.vue');
// prettier-ignore
const ResellerUpdate = () => import('@/entities/organization/reseller/reseller-update.vue');
// prettier-ignore
const ResellerDetails = () => import('@/entities/organization/reseller/reseller-details.vue');
// prettier-ignore
const ResellerImport = () => import('@/entities/organization/reseller/reseller-import.vue');
// prettier-ignore
const EmailTemplate  = () => import('@/entities/email-template/email-template.vue');
// prettier-ignore
const EmailTemplateUpdate  = () => import('@/entities/email-template/email-template-update.vue');
// prettier-ignore
const EmailTemplateView = () => import('@/entities/email-template/email-template-details.vue');
// prettier-ignore
const CustomTrialForm = () => import('@/entities/custom-trial-form/custom-trial-form.vue');
// prettier-ignore
const Dashboard = () => import('@/entities/dashboard/dashboard.vue');
// prettier-ignore
const DashboardReseller = () => import('@/entities/dashboard/dashboard-reseller.vue');
// prettier-ignore
const ResellerReport = () => import('@/entities/dashboard/dashboard-reseller-report.vue');
// prettier-ignore
const UserActivityReport = () => import('@/entities/dashboard/dashboard-user-activity.vue');
// prettier-ignore
const HybridViewerReport = () => import('@/entities/dashboard/dashboard-hybrid-viewer.vue');
// prettier-ignore
const PaymentLink = () => import('@/entities/payment-link/payment-link.vue');
// prettier-ignore
const PaymentLinkCreate = () => import('@/entities/payment-link/payment-link-create.vue');
// prettier-ignore
const PaymentLinkUpdate = () => import('@/entities/payment-link/payment-link-update.vue');
// prettier-ignore
const PaymentLinkDetails = () => import('@/entities/payment-link/payment-link-details.vue');
// prettier-ignore
const EndPointUpdate = () => import('@/entities/end-points/endpoints-update.vue');
// prettier-ignore
const EndPointDetails = () => import('@/entities/end-points/endpoint-details.vue');
// prettier-ignore
const UserActivityReportDetail = () => import('@/entities/dashboard/dashboard-user-activity-detail.vue');
// prettier-ignore
const Outbox = () => import('@/entities/outbox/outbox.vue');
// prettier-ignore
const NotificationUpdate = () => import('@/entities/notification/notification-update.vue');
// prettier-ignore
const NotificationtDetails = () => import('@/entities/notification/notification-details.vue');
// prettier-ignore
const TrialCreate = () => import('@/entities/trial/trial-create.vue');
const Encode = () => import('@/entities/encode/encode.vue');
// const SettingUpdate = () => import('@/entities/setting/setting-update.vue');
// // prettier-ignore
// const SettingDetails = () => import('@/entities/setting/setting-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default [
  {
    path: '/api-key',
    name: 'ApiKey',
    component: ApiKey,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/api-key/new',
    name: 'ApiKeyCreate',
    component: ApiKeyUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/api-key/:id/edit',
    name: 'ApiKeyEdit',
    component: ApiKeyUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/api-key/:id/view',
    name: 'ApiKeyView',
    component: ApiKeyDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/application-user',
    name: 'ApplicationUser',
    component: ApplicationUser,
    meta: { authorities: [
      Authority.ADMINISTRATOR,
      Authority.ADMIN,
      Authority.FULFILLMENT,
      Authority.FULFILLMENT_RO,
      Authority.ORG_ADMIN,
      Authority.ACCOUNT_MANAGER,
      Authority.RESELLER] },
  },
  {
    path: '/application-user/new',
    name: 'ApplicationUserCreate',
    component: ApplicationUserUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT,  Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/application-user/:id/edit',
    name: 'ApplicationUserEdit',
    component: ApplicationUserUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT,  Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/application-user/:id/view',
    name: 'ApplicationUserView',
    component: ApplicationUserDetails,
    meta: { authorities: [
      Authority.ADMINISTRATOR,
      Authority.ADMIN,
      Authority.FULFILLMENT,
      Authority.FULFILLMENT_RO,
      Authority.ORG_ADMIN,
      Authority.ACCOUNT_MANAGER,
      Authority.RESELLER
    ] },
  },
  {
    path: '/organization/:id/application-user/import',
    name: 'ApplicationUserImport',
    component: ApplicationUserImport,
    meta: { authorities: [
      Authority.ADMINISTRATOR,
      Authority.ADMIN,
      Authority.FULFILLMENT,
      Authority.ORG_ADMIN,
      Authority.ACCOUNT_MANAGER,
      Authority.RESELLER
    ] },
  },
  {
    path: '/elb-subscription',
    name: 'ElbSubscription',
    component: ElbSubscription,
    meta: { authorities: [Authority.ADMINISTRATOR,Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/elb-subscription/org',
    name: 'ElbSubscriptionOrg',
    component: ElbSubscriptionOrg,
    meta: { authorities: [Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ORG_ADMIN, Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/elb-subscription/new',
    name: 'ElbSubscriptionCreate',
    component: ElbSubscriptionUpdate,
    meta: { authorities: [Authority.USER, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/elb-subscription/:id/edit',
    name: 'ElbSubscriptionEdit',
    component: ElbSubscriptionUpdate,
    meta: { authorities: [Authority.USER, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/elb-subscription/:id/view',
    name: 'ElbSubscriptionView',
    component: ElbSubscriptionDetails,
    meta: { authorities: [Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ORG_ADMIN, Authority.ADMINISTRATOR, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/internal/elb-subscription/:id/view',
    name: 'ElbSubscriptionInternalView',
    component: ElbSubscriptionInternalDetails,
    meta: { authorities: [Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/organization',
    name: 'Organization',
    component: Organization,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.RESELLER] },
  },
  {
    path: '/organization/new',
    name: 'OrganizationCreate',
    component: OrganizationCreate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.RESELLER] },
  },
  {
    path: '/organization/:id/edit',
    name: 'OrganizationEdit',
    component: OrganizationUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.RESELLER] },
  },
  {
    path: '/organization/:id/view',
    name: 'OrganizationView',
    component: OrganizationDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.RESELLER] },
  },
  {
    path: '/plan',
    name: 'Plan',
    component: Plan,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan/new',
    name: 'PlanCreate',
    component: PlanCreate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan/:id/edit',
    name: 'PlanEdit',
    component: PlanUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan/:id/view',
    name: 'PlanView',
    component: PlanDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan-feature',
    name: 'PlanFeature',
    component: PlanFeature,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan-feature/new',
    name: 'PlanFeatureCreate',
    component: PlanFeatureUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan-feature/:id/edit',
    name: 'PlanFeatureEdit',
    component: PlanFeatureUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/plan-feature/:id/view',
    name: 'PlanFeatureView',
    component: PlanFeatureDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/article',
    name: 'Article',
    component: Article,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/article/new',
    name: 'ArticleCreate',
    component: ArticleUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },  },
  {
    path: '/article/:id/edit',
    name: 'ArticleEdit',
    component: ArticleUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },  },
  {
    path: '/article/:id/view',
    name: 'ArticleView',
    component: ArticleDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product',
    name: 'Product',
    component: Product,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product/new',
    name: 'ProductCreate',
    component: ProductUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product/:id/edit',
    name: 'ProductEdit',
    component: ProductUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product/:id/view',
    name: 'ProductView',
    component: ProductDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product-version/detail',
    name: 'ProductVersionDetail',
    component: ProductVersionDetail,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
    props: (route) => ({
      productVersion:  route.query.productVersion
    })
  },
  {
    path: '/product-version/apps',
    name: 'ProductVersionApps',
    component: ProductVersionApps,
    meta: { authorities: [Authority.ADMINISTRATOR,
        Authority.ADMIN, Authority.USER,
        Authority.ORG_ADMIN, Authority.AUTHOR,
        Authority.STUDENT, Authority.FULFILLMENT,
        Authority.FULFILLMENT_RO, Authority.ACCOUNT_MANAGER,
        Authority.STUDENT] },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN,
        Authority.USER, Authority.ORG_ADMIN,
        Authority.AUTHOR, Authority.STUDENT,
        Authority.FULFILLMENT, Authority.FULFILLMENT_RO,
        Authority.RESELLER, Authority.INSTRUCTOR,
        Authority.REPORTER] }
  },
  {
    path: '/product-version',
    name: 'ProductVersion',
    component: ProductVersion,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product-version/new',
    name: 'ProductVersionCreate',
    component: ProductVersionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product-version/:id/edit',
    name: 'ProductVersionEdit',
    component: ProductVersionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/product-version/:id/view',
    name: 'ProductVersionView',
    component: ProductVersionDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/setting/new',
    name: 'SettingCreate',
    component: SettingCreate,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/setting/:id/edit',
    name: 'SettingEdit',
    component: SettingUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },{
    path: '/intenal/organization/:organizationId/setting/:id/edit',
    name: 'SettingInternalEdit',
    component: SettingInternalUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/setting/:id/view',
    name: 'SettingView',
    component: SettingDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.RESELLER] },
  },
  {
    path: '/web-hook-version',
    name: 'WebHookVersion',
    component: WebHookVersion,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-version/new',
    name: 'WebHookVersionCreate',
    component: WebHookVersionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-version/:id/edit',
    name: 'WebHookVersionEdit',
    component: WebHookVersionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-version/:id/view',
    name: 'WebHookVersionView',
    component: WebHookVersionDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-event',
    name: 'WebHookEvent',
    component: WebHookEvent,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  // {
  //   path: '/web-hook-event/new',
  //   name: 'WebHookEventCreate',
  //   component: WebHookEventUpdate,
  //   meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  // },
  // {
  //   path: '/web-hook-event/:id/edit',
  //   name: 'WebHookEventEdit',
  //   component: WebHookEventUpdate,
  //   meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  // },
  {
    path: '/web-hook-event/:id/view',
    name: 'WebHookEventView',
    component: WebHookEventDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-endpoint',
    name: 'WebHookEndpoint',
    component: WebHookEndpoint,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-endpoint/new',
    name: 'WebHookEndpointCreate',
    component: WebHookEndpointUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-endpoint/:id/edit',
    name: 'WebHookEndpointEdit',
    component: WebHookEndpointUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/web-hook-endpoint/:id/view',
    name: 'WebHookEndpointView',
    component: WebHookEndpointDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/elb-event',
    name: 'ElbEvent',
    component: ElbEvent,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/elb-event/:id/view',
    name: 'ElbEventView',
    component: ElbEventDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/elb-resource',
    name: 'ElbResource',
    component: ElbResource,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/elb-resource/new',
    name: 'ElbResourceCreate',
    component: ElbResourceUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/elb-resource/:id/edit',
    name: 'ElbResourceEdit',
    component: ElbResourceUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/elb-resource/:id/view',
    name: 'ElbResourceView',
    component: ElbResourceDetails,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/region',
    name: 'Region',
    component: Region,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/region/new',
    name: 'RegionCreate',
    component: RegionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/region/:id/edit',
    name: 'RegionEdit',
    component: RegionUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/hub-spot-form',
    name: 'HubSpotForm',
    component: HubSpotForm,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/hub-spot-form/new',
    name: 'HubSpotFormCreate',
    component: HubSpotFormUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/hub-spot-form/:id/edit',
    name: 'HubSpotFormEdit',
    component: HubSpotFormUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/server/setting',
    name: 'Server',
    component: Server,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/addon',
    name: 'Addon',
    component: Addon,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/addon/new',
    name: 'AddonCreate',
    component: AddonUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/addon/:id/edit',
    name: 'AddonEdit',
    component: AddonUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/addon/:id/view',
    name: 'AddonView',
    component: AddonDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.ADMIN] },
  },
  {
    path: '/reseller',
    name: 'Reseller',
    component: Reseller,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/reseller/new',
    name: 'ResellerCreate',
    component: ResellerUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/reseller/:id/edit',
    name: 'ResellerEdit',
    component: ResellerUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/reseller/:id/view',
    name: 'ResellerView',
    component: ResellerDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/reseller/:id/import',
    name: 'ResellerImport',
    component: ResellerImport,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/reseller/email-template',
    name: 'EmailTemplate',
    component: EmailTemplate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/reseller/email-template/:id',
    name: 'EmailTemplateUpdate',
    component: EmailTemplateUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/reseller/email-template/:id/view',
    name: 'EmailTemplateView',
    component: EmailTemplateView,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.RESELLER] },
  },
  {
    path: '/reseller/custom-trial-form/',
    name: 'CustomTrialForm',
    component: CustomTrialForm,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.RESELLER] },

  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER] },
  },
  {
    path: '/dashboard/reseller',
    name: 'DashboardReseller',
    component: DashboardReseller,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER] },
  },
  {
    path: '/dashboard/reseller/:id/report',
    name: 'ResellerReport',
    component: ResellerReport,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER] },
  },
  {
    path: '/dashboard/user-activity',
    name: 'UserActivityReport',
    component: UserActivityReport,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/dashboard/user-activity/:id',
    name: 'UserActivityReportDetail',
    component: UserActivityReportDetail,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/dashboard/hybrid-viewer',
    name: 'HybridViewerReport',
    component: HybridViewerReport,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.REPORTER] },
  },
  {
    path: '/payment-link',
    name: 'PaymentLink',
    component: PaymentLink,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/payment-link/new',
    name: 'PaymentLinkCreate',
    component: PaymentLinkCreate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/payment-link/:id/edit',
    name: 'PaymentLinkEdit',
    component: PaymentLinkUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/payment-link/:id/view',
    name: 'PaymentLinkView',
    component: PaymentLinkDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  },
  {
    path: '/end-point/:id/new',
    name: 'EndPointCreate',
    component: EndPointUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/end-point/:id/view',
    name: 'EndPointView',
    component: EndPointDetails,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/end-point/:id/edit',
    name: 'EndPointEdit',
    component: EndPointUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/outbox',
    name: 'Outbox',
    component: Outbox,
    meta: { authorities: [Authority.ADMINISTRATOR] },
  },
  {
    path: '/notification/new',
    name: 'NotificationCreate',
    component: NotificationUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/notification/:id/view',
    name: 'NotificationView',
    component: NotificationtDetails,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/notification/:id/edit',
    name: 'NotificationEdit',
    component: NotificationUpdate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT] },
  },
  {
    path: '/trail/new',
    name: 'TrialCreate',
    component: TrialCreate,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO, Authority.RESELLER] },
  },
  // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  {
    path: '/encode',
    name: 'Encode',
    component: Encode,
    meta: { authorities: [Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO] },
  }
];
